.text-image-underline {
    position: relative;
    display: inline-block;
}

.text-image-underline::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5px; /* Adjust this to control how far below the text the underline appears */
    height: 20px; /* Height of the image */
    background: url('../assets/underlineImage.png') no-repeat center;
    background-size: contain; /* Ensure the image fits the underline space */
    width: 100%; /* Make the image cover the entire width of the text */
}

.rpv-core__viewer--dark .rpv-core__inner-page {
    background-color: #5EFF9E;
 }
 
 .rpv-core__viewer--dark .rpv-core__text-layer {
   opacity: 50%;
 }


